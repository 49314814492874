import { AuthLayout } from '@frontend/feature-auth';
import { Button, Typography } from 'antd';
import getConfig from 'next/config';
import Head from 'next/head';
import Link from 'next/link';
import React, { ReactElement } from 'react';

import type { NextPageWithLayout } from './_app';

const NotFoundPage: NextPageWithLayout = () => {
  const { publicRuntimeConfig } = getConfig();
  return (
    <>
      <Head>
        <title>Not found</title>
        <meta name="description" content="" />
        <link
          rel="icon"
          href={`${publicRuntimeConfig.staticFolder}/favicon.ico`}
        />
      </Head>
      <div className="text-center">
        <div className="pb-4">
          <Typography.Title level={3}>Not found</Typography.Title>
          <span className="font-normal text-sm">
            The page you are trying to access does not exist or no longer
            exists. Please check the link to the page you are trying to access
          </span>
        </div>
        <Link href={'/'}>
          <Button shape="round" type="primary">
            Go back to Bridge
          </Button>
        </Link>
      </div>
    </>
  );
};

NotFoundPage.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>;
};

export default NotFoundPage;
